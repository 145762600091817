import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import httpAxios from "../../repositores";

interface AuthGuardType {
  children: React.ReactNode;
}
const UNAUTHORIZED = 401;

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isAuthenticated, isInitialized, signOut } = useAuth();

  // Intercepta se o status retorna 401
  httpAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === UNAUTHORIZED) {
        signOut();
        return Promise.reject();
      }

      return Promise.reject(error);
    }
  );

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
