import styled from "styled-components";

export const Container = styled.main`
  > header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    z-index: 2021;

    @media (min-width: 1921px) {
      width: 1920px;
      margin-left: 310px;
    }

    background: linear-gradient(180deg, #0f1116 -50%, transparent);
    transition: background-color 0.5s;

    &.sticky {
      background-color: #0f1116;
    }

    .header-container {
      width: 100%;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-left {
        display: flex;
        align-items: center;
      }
    }

    .header-brand {
      margin-right: 3rem;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 2.5rem;
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    .header-navegation {
      display: none;

      @media (min-width: 992px) {
        display: block;
      }

      ul {
        display: flex;
        align-items: center;
        grid-gap: 4rem;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          &.active {
            a {
              color: ${({ theme }) => theme.colors.white};

              &::after {
                width: 24px;
              }
            }
          }

          a {
            position: relative;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1rem;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: hsla(0, 0%, 100%, 0.7);
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -11px;
              width: 0;
              height: 2px;
              background: linear-gradient(
                267.26deg,
                ${({ theme }) => theme.colors.primary} -0.71%,
                ${({ theme }) => theme.colors.secondary} 130.41%
              );
              transition: width 0.3s;
            }

            &:hover {
              color: ${({ theme }) => theme.colors.white};

              &::after {
                width: 24px;
              }
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;

      &-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background: none;

        font-size: 2.5rem;
        color: ${({ theme }) => theme.colors.gray};

        .mobile {
          display: block;

          @media (min-width: 992px) {
            display: none;
          }
        }

        .desktop {
          display: none;

          @media (min-width: 992px) {
            display: block;
          }
        }

        @media (min-width: 992px) {
          border-radius: 50%;
          border: 0;
          background: ${({ theme }) => theme.colors.primary};

          width: 2.5rem;
          height: 2.5rem;
          color: ${({ theme }) => theme.colors.graydark};
          font-size: 1.5rem;
          transition: all 0.3s ease-in-out;
          box-shadow: 0 0 0 4px rgb(255 255 255 / 10%);

          &:hover {
            background: ${({ theme }) => theme.colors.graydark};
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
  }

  > footer {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);

    .footer-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem 0;
      grid-gap: 2rem;

      @media (min-width: 992px) {
        padding: 4rem 0;
        grid-gap: 6rem;
        flex-direction: row;
      }

      .footer-brand {
        img {
        }

        p {
          margin-top: 2rem;
          margin-bottom: 2rem;
          font-size: 1rem;
          line-height: 150%;
          color: #e5e5e5;
          opacity: 0.8;
        }

        button {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.7rem;
          text-align: right;
          color: ${({ theme }) => theme.colors.white};
          background: none;
          border: 0;

          svg {
            color: ${({ theme }) => theme.colors.primary};
            margin-left: 1rem;
          }
        }
      }

      > nav {
        flex: 1;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        grid-gap: 2rem;

        @media (min-width: 992px) {
          flex-direction: row;
          grid-gap: 6rem;
        }

        .nav-column {
          h3 {
            display: block;
            font-weight: 700;
            font-size: 1.3rem;
            line-height: 150%;
            color: ${({ theme }) => theme.colors.white};
            margin-bottom: 2.4rem;
          }

          ul {
            display: flex;
            flex-direction: column;
            grid-gap: 1rem;
            padding: 0;
            margin: 0;
            list-style-type: none;

            &.socias {
              flex-direction: row;
              grid-gap: 0.7rem;
            }

            li {
              a {
                display: flex;
                align-items: center;
                font-size: 1rem;
                line-height: 150%;
                color: #86888d;
                text-decoration: none;
                transition: color 0.3s;

                &:hover {
                  color: ${({ theme }) => theme.colors.white};

                  svg {
                    color: ${({ theme }) => theme.colors.white};
                  }
                }

                svg {
                  color: ${({ theme }) => theme.colors.primary};
                  font-size: 1.6rem;
                }
              }
            }
          }
        }
      }
    }
  }
`;
