import { useCallback, useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

import { IoLogoInstagram, IoMdPerson } from "react-icons/io";
import { CgMenuRight } from "react-icons/cg";

import { MenuDrawer } from "components/MenuDrawer";

import Logo from "assets/logo.png";

import { Container } from "./styles";

export const AuthLayout = (): JSX.Element => {
  const location = useLocation();

  const [openMenu, setOpenMenu] = useState(false);

  const handleScroll = useCallback(() => {
    let header = document.querySelector(".header");

    if (window.scrollY > 10) {
      header?.classList.add("sticky");
      return;
    }

    header?.classList.remove("sticky");
  }, []);

  function handleToggleMenu() {
    setOpenMenu((prevState) => !prevState);
  }

  // const handleScrollTop = useCallback(() => {
  //   let top = document.querySelector("#top");
  //
  //   top?.scrollIntoView({
  //     behavior: "smooth",
  //   });
  // }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Container id="top">
      <header className="header">
        <div className="container-fluid">
          <div className="header-container">
            <div className="header-container-left">
              <div className="header-brand">
                <Link to="/">
                  <img src={Logo} alt="Ttribo.com" width={50} />
                </Link>
              </div>
              <nav className="header-navegation">
                <ul>
                  <li>
                    <a href="https://app.ttribo.com" rel={"noreferrer"}>
                      Início
                    </a>
                  </li>
                  <li className={location.pathname === "/" ? "active" : ""}>
                    <Link to={"/"}>Dados Cadastrais</Link>
                  </li>
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    location.pathname === "/progresso" ? "active" : ""*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link to={"/progresso"}>Progresso</Link>*/}
                  {/*</li>*/}
                  <li
                    className={location.pathname === "/compras" ? "active" : ""}
                  >
                    <Link to={"/compras"}>Compras</Link>
                  </li>
                  <li
                    className={location.pathname === "/senha" ? "active" : ""}
                  >
                    <Link to={"senha"}>Alterar senha</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="actions">
              <button
                className="actions-menu"
                onClick={() => handleToggleMenu()}
              >
                <IoMdPerson className="desktop" />
                <CgMenuRight className="mobile" />
              </button>
            </div>
          </div>
        </div>
      </header>

      <MenuDrawer open={openMenu} onClose={() => handleToggleMenu()} />

      <Outlet />

      <footer>
        <div className="container-fluid">
          <div className="footer-container">
            <div className="footer-brand">
              <img src={Logo} alt="Ttribo.com" width={50} />

              <p>© ttribo.com. Todos os direitos reservados</p>
            </div>
            <nav>
              <div className="nav-column">
                <h3>Link Úteis</h3>

                <ul>
                  <li>
                    <a
                      href="https://app.ttribo.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vídeos
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://comunidade.ttribo.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Comunidade
                    </a>
                  </li>
                </ul>
              </div>

              <div className="nav-column">
                <h3>Termos e ajuda</h3>

                <ul>
                  <li>
                    <Link to="/">Central de Ajuda</Link>
                  </li>
                  <li>
                    <Link to="/">Termos de uso</Link>
                  </li>
                </ul>
              </div>

              <div className="nav-column">
                <h3>Redes Sociais</h3>

                <ul className="socias">
                  <li>
                    <a
                      href="https://www.instagram.com/ttribo.oficial/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoLogoInstagram />
                    </a>
                  </li>
                  {/*<li>*/}
                  {/*  <a href="/" target="_blank" rel="noopener noreferrer">*/}
                  {/*    <IoLogoLinkedin />*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a href="/" target="_blank" rel="noopener noreferrer">*/}
                  {/*    <IoLogoTwitter />*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a href="/" target="_blank" rel="noopener noreferrer">*/}
                  {/*    <IoLogoYoutube />*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </footer>
    </Container>
  );
};
