import httpAxios from "./index";
import { User } from "../types/Auth";

class AuthClass {
  user() {
    return httpAxios.get("api/user");
  }

  update(user: User) {
    return httpAxios.put("api/customer", user);
  }

  purchases() {
    return httpAxios.get("api/hotmart/orders");
  }

  forgetPassword(email: string) {
    return httpAxios.post("forget-password", {
      email: email,
    });
  }

  verifyToken(email: string, token: string) {
    return httpAxios.post("verify-token", {
      email: email,
      token: token,
    });
  }

  resetPassword(data: any) {
    return httpAxios.post("reset-password", data);
  }
}

const authHttp = new AuthClass();

export default authHttp;
