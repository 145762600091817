import axios from "axios";

export const httpAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

httpAxios.interceptors.request.use(function (config) {
  const accessToken = window.localStorage.getItem("accessToken");
  if (accessToken) {
    // @ts-ignore
    config.headers["Authorization"] = "Bearer " + accessToken;
  }
  // Do something before request is sent

  return config;
});

export default httpAxios;
