const theme = {
  colors: {
    primary: "#F7E582",
    primarylight: "#3399cda5",
    secondary: "#646E82",
    success: "#31F07B",
    warning: "#ffb425",
    error: "#f98585",
    info: "#00e7f9",
    attention: "#E83F5B",

    white: "#ffffff",
    black: "#000000",
    blue: "#3399cd",
    red: "#ce2029",
    yellow: "#ffb425",
    gray: "#C4C4C4",
    graydark: "#4f5560",

    background: "#000000",
    border: "#28272c",
    text: "#000",
    //text: "#C4C4C4",
    title: "#191b21",
  },
  fonts: {
    roboto: "Roboto",
  },
};

export default theme;
