import styled from "styled-components";

export const Container = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2026;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  &.show {
    opacity: 1;
    pointer-events: all;

    .card {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(0, 0, 0, 0.8);
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
  }

  .card {
    width: 25rem;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 2;
    transform: translateX(100%);
    opacity: 0.1;
    transition: all 0.4s;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 88px;
      padding: 0 1.8rem;

      h3 {
        display: none;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.8rem;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.white};

        @media (min-width: 992px) {
          display: flex;
        }
      }

      img {
        display: flex;

        @media (min-width: 992px) {
          display: none;
        }
      }

      button {
        background: none;
        border: 0;
        color: ${({ theme }) => theme.colors.primary};
        font-size: 2rem;
      }
    }

    &-content {
      height: calc(100vh - 8.8rem);
      overflow-y: auto;
      padding: 2.8rem 1.8rem;
      border-top: 1.5px solid #fff;

      &--user {
        display: flex;
        align-items: center;
        border-bottom: 1.5px solid #fff;
        padding-bottom: 4rem;
        margin-bottom: 4rem;

        picture {
          border: 0;
          border-radius: 10px;
          background-color: ${({ theme }) => theme.colors.primary};
          width: 5rem;
          height: 5rem;
          align-items: center;
          display: flex;
          justify-content: center;
          overflow: hidden;

          svg {
            color: ${({ theme }) => theme.colors.white};
            font-size: 3rem;
          }
        }

        .info {
          margin-left: 0.5rem;

          span {
            font-size: 1.2rem;
            line-height: 1;
            color: ${({ theme }) => theme.colors.white};
          }

          h4 {
            color: ${({ theme }) => theme.colors.white};
            font-size: 1.6rem;
            line-height: 2.5rem;
            margin-bottom: 0.1rem;
          }

          em {
            display: block;
            font-weight: 600;
            font-size: 1rem;
            font-style: normal;
            line-height: 1;
            color: ${({ theme }) => theme.colors.white};
          }
        }
      }

      &--menu {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            &.mobile {
              display: block;

              @media (min-width: 992px) {
                display: none;
              }
            }

            &.desktop {
              display: none;

              @media (min-width: 992px) {
                display: block;
              }
            }

            button {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 3.5rem;
              text-decoration: none;
              transition: all 0.3s;
              width: 100%;

              background: none;
              border: 0;

              &:hover {
                span {
                  color: ${({ theme }) => theme.colors.primary};
                }
              }

              span {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 1.2rem;
                line-height: 150%;
                color: #fff;
                transition: color 0.3s;

                > svg {
                  font-size: 1.6rem;
                  color: ${({ theme }) => theme.colors.primary};
                  margin-right: 2rem;
                }
              }

              > svg {
                font-size: 1.6rem;
                color: ${({ theme }) => theme.colors.primary};
              }
            }
          }
        }
      }

      .btn-logout {
        border: 0;
        border-radius: 10px;
        background: ${({ theme }) => theme.colors.primary};
        width: 100%;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 1.5rem;
        color: ${({ theme }) => theme.colors.black};
        margin-top: 2.8rem;
        transition: all 0.3s;

        &:hover {
          background: ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`;
