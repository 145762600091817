// styles/globalStyles.ts
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  * {
    box-sizing: border-box;
    margin: 0;
    outline: 0;
    transition: 0.1s;
  }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%; // 15px
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%; // 14px
    }
  }

  body {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.background};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  input,
  textarea,
  button {
    font: 500 1rem ${({ theme }) => theme.fonts.roboto}, sans-serif;
    color: ${({ theme }) => theme.colors.text};
  }

  input, textarea {
    outline: 0px;
    background: rgb(18, 18, 20);
    border: 2px solid rgb(40, 39, 44);
    border-radius: 5px;
    height: 50px;
    padding: 15px 21px;
    color: rgb(255, 255, 255);
    font-size: 17px;
    width: 100%;
    transition: border 0.2s ease 0s;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    font-family: ${({ theme }) => theme.fonts.roboto}, sans-serif;
    color: ${({ theme }) => theme.colors.title};
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  button {
    cursor: pointer;
  }

  .container-fluid {
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto;

    @media (min-width: 992px) {
      padding: 0 1.5625rem;
    }
  }

  .container {
    width: 100%;
    max-width: 1310px;
    padding: 0 1rem;
    margin: 0 auto;

    @media (min-width: 992px) {
      padding: 0 1.5625rem;
    }
  }

  .section {
    padding: 3.5rem 0;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
   
   
    
    h3 {
      display: flex;
      align-items: center;
      grid-column-gap: 1rem;
      margin-bottom: 1.6rem;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: ${({ theme }) => theme.colors.white};

      @media (min-width: 992px) {
        margin-bottom: 3.2rem;
        font-size: 1.8rem;
        line-height: 2.2rem;
      }

      svg {
        font-size: 1.8rem;
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;
