import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { BrowserRouter, Routes as Switch, Route } from "react-router-dom";
import { AuthLayout, GuestLayout } from "Layout";
import { SIGNIN, FORGET, CHANGEPASSWORD } from "../constants";
import AuthGuard from "../components/guards/AuthGuards";
import LoadingScreen from "../components/LoadingScreen";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Assinatura = Loadable(lazy(() => import("pages/Profile/assinatura")));
const Profile = Loadable(lazy(() => import("pages/Profile")));
const SignIn = Loadable(lazy(() => import("pages/SignIn")));
const Forgot = Loadable(lazy(() => import("pages/Forgot")));
const Password = Loadable(lazy(() => import("pages/Profile/password")));
const ChangePassword = Loadable(lazy(() => import("pages/ChangePassword")));
const Progress = Loadable(lazy(() => import("pages/Progress")));

export const Routes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        <Route element={<GuestLayout />}>
          <Route path={SIGNIN} element={<SignIn />} />
          {/*<Route path="/signup" element={<SignUp />} />*/}
          <Route path={FORGET} element={<Forgot />} />
          <Route
            path={`${CHANGEPASSWORD}/:token/:email`}
            element={<ChangePassword />}
          />
        </Route>
        <Route
          element={
            <AuthGuard>
              <AuthLayout />
            </AuthGuard>
          }
        >
          <Route path="/" element={<Profile />} />
          <Route path="/compras" element={<Assinatura />} />
          <Route path="/senha" element={<Password />} />
          <Route path="/progresso" element={<Progress />} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
