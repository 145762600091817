import { AppProvider } from "./contexts";
import { Routes } from "./routes";

import GlobalStyles from "./themes/GlobalStyle";

const App = (): JSX.Element => {
  return (
    <AppProvider>
      <Routes />
      <GlobalStyles />
    </AppProvider>
  );
};

export default App;
