import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";
import { ActionMap, AuthState, SanctumContextType, User } from "../types/Auth";
import httpAxios from "../repositores";
import authHttp from "../repositores/auth";
import { setSession } from "../utils/token";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: User | null;
  };
  [SIGN_IN]: {
    user: User | null;
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    user: User | null;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const SanctumReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<SanctumContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(SanctumReducer, initialState);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const initialize = async () => {
      try {
        setLoading(true);
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken) {
          const response = await authHttp.user();
          if (response.status === 200) {
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user: response.data.data,
              },
            });
          }
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, []);

  const signIn = async (email: string, password: string) => {
    await httpAxios.get("sanctum/csrf-cookie");
    const { data } = await httpAxios.post("/token", {
      email: email,
      password: password,
      device_name: "web",
    });
    setSession(data.token);

    dispatch({
      type: SIGN_IN,
      payload: {
        user: data.user,
      },
    });

    // await httpAxios.get("sanctum/csrf-cookie").then(() => {
    //   httpAxios
    //     .post("/login", {
    //       email: email,
    //       password: password,
    //     })
    //     .then((response) => {
    //       dispatch({
    //         type: SIGN_IN,
    //         payload: {
    //           user: response.data,
    //         },
    //       });
    //     })
    //     .finally(() => {
    //       setLoading(false);
    //     });
    // });
  };

  const signOut = async () => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken) {
      await httpAxios.post("/revoke-token");
      setSession(null);
    }

    await dispatch({ type: SIGN_OUT });
  };

  const resetPassword = (email: string) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loading,
        method: "session",
        signIn,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export { AuthContext, AuthProvider };
